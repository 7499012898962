<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Staff Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['update:staff']">
        <button
          @click="updateStaffFields"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Details</span>
        </button>
      </is-authorized>
    </div>
    <div class="grid grid-cols-3 md:grid-cols-3 grid-flow-col gap-10">
      <div class="row-span-3 col-span-2">
        <div class="flex flex-wrap -my-3 -mx-5">
          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Date of Birth<span class="text-red-400">*</span></label
            >
            <Calendar
              v-model="staff.dob"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm">Allergies</label>
            <input
              v-model="staff.allergies"
              class="bge-input bge-input-spacing rounded"
              name="manufacturer"
              type="text"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >EMPLOYMENT START DATE<span class="text-red-400">*</span></label
            >
            <Calendar
              v-model="staff.doj"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm"
              >Company Vehicle</label
            >
            <input
              v-model="staff.vehicle"
              class="bge-input bge-input-spacing rounded"
              disabled
              name="make"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="row-span-3">
        <div class="flex flex-wrap -my-3 -mx-5">
          <div class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3">
            <label class="uppercase font-semibold text-sm">Notes</label>
            <textarea
              v-model="staff.notes"
              class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
              placeholder="Note..."
              rows="10"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Vehicle Information -->

    <!-- /Registration Details -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { COUNTRIES } from "@/utils/constants";
import Notification from "@/components/Notification.vue";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Information",
  components: {
    Datepicker,
    Notification,
    IsAuthorized,
  },
  props: {
    staff: Object,
  },
  data() {
    return {
      countries: [],
    };
  },
  mounted() {
    this.countries = COUNTRIES;
  },
  methods: {
    async updateStaffFields() {
      let error;
      let msg;
      let isAdd = false;
      if (!this.staff.dob) {
        msg = "Please enter DOB.";
      } else if (
        !this.$validator.isBefore(
          this.staff.dob instanceof Date
            ? this.staff.dob.toDateString()
            : this.staff.dob,
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 13),
          ).toDateString(),
        )
      ) {
        msg = `Please enter dob before '${new Date(
          new Date().setFullYear(new Date().getFullYear() - 13),
        ).toDateString()}'`;
      } else if (!this.staff.doj) {
        msg = "Please enter DOJ.";
      } else if (
        !this.$validator.isBefore(
          this.staff.doj instanceof Date
            ? this.staff.doj.toDateString()
            : this.staff.doj,
          new Date(new Date().setDate(new Date().getDate() + 1)).toDateString(),
        )
      ) {
        msg = `Please enter doj before '${new Date(
          new Date().setDate(new Date().getDate() + 1),
        ).toDateString()}'`;
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg,
            );
          },
          {
            position: "top-right",
          },
        );
      }

      try {
        this.isBusy = true;
        await this.StaffService.updateStaff(this.staff);

        this.$emit("complete");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Update Successful",
                  close: onClose,
                },
              },
              `Details for '${this.staff.first_name} ${this.staff.last_name}' were updated`,
            );
          },
          {
            position: "top-right",
          },
        );
      } catch (e) {
        error = e;

        const errorMessage =
          error && error.data && error.data.message
            ? error.data.message.join(", ") // If it's an array of messages, join them
            : "Error occurred while updating info, please try again later";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              errorMessage,
            );
          },
          {
            position: "top-right",
          },
        );
      }

      this.isBusy = false;
    },
  },
};
</script>
<style>
.staffcalendar {
  bottom: 100% !important;
}
</style>
